<div class="row vh-100 d-flex align-items-center justify-content-center flex-column">
    <div class="">
        <img width="250px" src="../../assets/img/var.svg" class="psd-var__image mt-5 mb-5" />
    </div>
    <div>
        <div class="text-center">
            <h3 class="mt-0">{{ 'var.decision.title' | translate }}: {{ 'page.not.found' | translate }}</h3>
            <a [routerLink]="'/central-login/login'" class="btn psd mt-4">{{ 'Login' | translate }}</a>
        </div>
    </div>
</div>
