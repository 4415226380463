import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CustomPreloadingStrategyService } from 'psd-common';
import { PageNotFoundComponent } from '@app/modules/error/page-not-found.component';

const appRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('../app/modules/central-login/central-login.module').then(m => m.CentralLoginModule),
    data: { preload: false, delay: true, module: 'central-login' }
  },
  {
    path: 'onboarding',
    loadChildren: () => import('../app/modules/onboarding/onboarding.module').then(m => m.OnboardingModule),
    data: { preload: false, delay: true, module: 'onboarding' }
  },
  {
    path: 'central-login',
    loadChildren: () => import('../app/modules/central-login/central-login.module').then(m => m.CentralLoginModule),
    data: { preload: false, delay: true, module: 'central-login' }
  },
  {
    path: 'staging-sso',
    loadChildren: () => import('../app/modules/sso/sso.module').then(m => m.SsoModule),
    data: { preload: false, delay: true, module: 'staging-sso' }
  },
  {
    path: 'payment',
    loadChildren: () => import('../app/modules/payment/payment.module').then(m => m.PaymentModule),
    data: { preload: false, delay: true, module: 'payment' }
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    data: { preload: false, delay: false }
  }
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            appRoutes,
            {
              enableTracing: false,
              preloadingStrategy: CustomPreloadingStrategyService,
              onSameUrlNavigation: 'reload'
            }
        )
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
