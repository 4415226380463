import { Component, ElementRef, Inject, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToasterConfig } from 'angular2-toaster';
import { Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';

import { AuthenticationService, CommonUserService, CookieService, UserUtil } from 'psd-common';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {
    public languagesInitialized: boolean = false;
    public loadLanguagesBusy: Subscription;

    public toasterconfig: ToasterConfig = new ToasterConfig({
        showCloseButton: true,
        tapToDismiss: true,
        timeout: 10000
    });

    constructor(@Inject(DOCUMENT) private document: Document,
                private renderer: Renderer2,
                private router: Router,
                private userService: CommonUserService,
                private cookieService: CookieService,
                private authenticationService: AuthenticationService,
                private translateService: TranslateService,
                private elementRef: ElementRef,) {
        localStorage.setItem('apiBaseURL', environment.publicAppAPIUrl);

        // if a user is logged in, take the language of the current central user
        // if a user is not logged in, take the language of the browser
        this.authenticationService.isLoggedIn(environment.production).subscribe(_isLoggedIn => {
            if(_isLoggedIn) {
                this.getCurrentUser().then(() => {
                    this.languagesInitializedActions();
                });
            } else {
                this.translateService.use(UserUtil.getUserLanguage());
                this.languagesInitializedActions();
            }
        });

        if (environment.production) {
            this.elementRef.nativeElement.removeAttribute('ng-version');
        }
    }

    getCurrentUser(): Promise<any> {
        return new Promise( (resolve, reject) => {
            this.loadLanguagesBusy = this.userService.getCurrentCentralUser(environment.production).subscribe(_result => {
                this.translateService.use(_result.language.toLowerCase());
                localStorage.setItem('user', JSON.stringify(_result));
                resolve();
            }, (_: Error) => {
                this.translateService.use(UserUtil.getUserLanguage());
                reject();
            });
        })

    }

    private languagesInitializedActions() {
        this.languagesInitialized = true;
        this.removeBackgroundImage();
    }

    private removeBackgroundImage() {
        this.renderer.removeClass(this.document.body, 'psd-bg-img');
    }
}
