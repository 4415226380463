export const environment = {
  production: true,
  adyenProduction: true,
  publicAppAPIUrl: 'https://psd.prosoccerdata.com/api/v2',
  apiPath: '/api/v2',
  recaptcha: {
    siteKey: '6LdXZW4aAAAAAIZUk7DgN7yAxP5vbnlWiEBul0vm',
    serverKey: '6LdXZW4aAAAAAHzdkisNWCdaLL0r1SRd1VFuDriB'
  },
  adyenClientKey: 'live_R2WRL3IVZZCBRGW7VFAHP3XY7UXMTXIE'
};
