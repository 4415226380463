import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { ToasterModule } from 'angular2-toaster';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxSpinnerModule } from 'ngx-spinner';

import {
    PsdCommonModule,
    HttpAuthentication,
    ConfigService,
    CookieService,
    AuthenticationService,
    CommonCacheService,
    CommonBusyDirective,
    ClubService,
    CustomPreloadingStrategyService,
    TranslationService,
    Toaster,
    DataListService,
    ModuleService,
    PlatformPackageService,
    ConfigParamsService,
    CommonPlatformService,
    CommonUserService,
    BillingService,
    URLParams
} from 'psd-common';

@NgModule({
    imports: [PsdCommonModule, FormsModule, ReactiveFormsModule, CommonModule, HttpClientModule, ModalModule, NgSelectModule, TranslateModule, TabsModule, NgxSpinnerModule, ToasterModule.forRoot(), TooltipModule, PopoverModule.forRoot(), RecaptchaModule, RecaptchaFormsModule],
    declarations: [],
    exports: [PsdCommonModule, FormsModule, ReactiveFormsModule, CommonModule, HttpClientModule, ModalModule, NgSelectModule, TranslateModule, TabsModule, NgxSpinnerModule, ToasterModule, TooltipModule, PopoverModule, RecaptchaModule, RecaptchaFormsModule, CommonBusyDirective],
    entryComponents: []
})
export class SharedModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedModule,
            providers: [
                HttpAuthentication,
                AuthenticationService,
                CommonCacheService,
                ConfigParamsService,
                ConfigService,
                CustomPreloadingStrategyService,
                ModuleService,
                TranslationService,
                ClubService,
                DataListService,
                PlatformPackageService,
                CookieService,
                CommonPlatformService,
                Toaster,
                CommonUserService,
                BillingService,
                URLParams
            ]
        };
    }
}
