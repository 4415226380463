import { CommonModule } from '@angular/common';
import {ErrorHandler, Injector, NgModule} from '@angular/core';
import {BrowserModule, DomSanitizer} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { ModalModule } from 'ngx-bootstrap/modal';
import * as Sentry from '@sentry/browser';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {CommonUtil, TranslationService} from 'psd-common';
import { PSDTranslateLoader } from 'psd-common';
import { SharedModule } from '@app/modules/shared/shared.module';
import { GlobalErrorHandler } from 'psd-common';
import { environment } from '../environments/environment';
import { PageNotFoundComponent } from '@app/modules/error/page-not-found.component';

// Sentry.init({
//     dsn: 'https://7d21967ad8b649b5990bae3fa2aa8078@o107171.ingest.sentry.io/6418295',
//     debug: false,
//     release: '1',
//     environment: environment.production.toString(),
//     ignoreErrors: [
//         /Non-Error exception captured*/,
//         /Unexpected token E in JSON*/,
//         /JSON Parse error: Unexpected identifier "Er"*/,
//         /Unexpected end of JSON input*/,
//         'Could not refresh token',
//         'Attempted to assign to readonly property',
//         'Assignment to read-only properties is not allowed in strict mode'
//     ],
//     beforeSend(data: any, hint: any) {
//         let sendCallback: boolean = true;
//         if (
//             (data != null &&
//                 data.exception != null &&
//                 typeof data.exception != 'undefined' &&
//                 data.exception.values != null &&
//                 data.exception.values.length > 0 &&
//                 (data.exception.values[0].value == 'Could not refresh token' ||
//                     data.exception.values[0].value.indexOf('Non-Error exception captured') >= 0 ||
//                     data.exception.values[0].value.indexOf('JSON Parse error: Unexpected identifier "Er"') >= 0)) ||
//             data.exception.values[0].value.indexOf('Unexpected end of JSON input') >= 0 ||
//             data.exception.values[0].value.indexOf('Unexpected token E in JSON') >= 0 ||
//             data.exception.values[0].value.indexOf("'undefined' is not a function") >= 0 ||
//             data.exception.values[0].value.indexOf('AbortError: The operation was aborted.') >= 0 ||
//             data.exception.values[0].value.indexOf('Error: aborted') >= 0 ||
//             data.exception.values[0].value.indexOf('Error: AbortError') >= 0 ||
//             data.exception.values[0].value.indexOf('AbortError') >= 0 ||
//             data.exception.values[0].value.indexOf('THEOplayer') >= 0 ||
//             data.exception.values[0].value.indexOf('Unable to fetch HLS manifest') >= 0 ||
//             data.exception.values[0].value.indexOf('Unexpected source type') >= 0 ||
//             data.exception.values[0].value.indexOf('Unable to persist task - Storage disabled') >= 0 ||
//             data.exception.values[0].value.indexOf('Error: Transaction aborted') >= 0
//         ) {
//             sendCallback = false;
//         }
//
//         if (sendCallback) {
//             return data;
//         } else {
//             return null;
//         }
//     }
// });

@NgModule({
    imports: [
        AppRoutingModule,
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        ModalModule.forRoot(),
        SharedModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translateLoader,
                deps: [TranslationService]
            }
        })
    ],
    declarations: [
        AppComponent,
        PageNotFoundComponent
    ],
    bootstrap: [AppComponent],
    providers: [
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {
                siteKey: environment.recaptcha.siteKey,
            } as RecaptchaSettings,
        }
    ]
})
export class AppModule {
    constructor(private injector: Injector) {
        CommonUtil.setInjector(injector);
    }
}

export function translateLoader(translationService: TranslationService) {
    return new PSDTranslateLoader(translationService);
}
